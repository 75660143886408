import React, { useState } from 'react';
import veritabani from './veritabani';

    function Giris({ onGiris }) {
      const [telefon, setTelefon] = useState('');

      const handleSubmit = (e) => {
        e.preventDefault();
        const kullanici = veritabani[telefon];
        if (kullanici) {
          onGiris(kullanici);
        } else {
          alert('Kullanıcı bulunamadı!');
        }
      };

      return (
        <div style={{ textAlign: 'center' }}>
          <h1>Hoş Geldiniz</h1>
          <img
            src="komik-gif.gif" 
            alt="Komik GIF"
            style={{ maxWidth: '300px', marginBottom: '20px' }}
          />
          <p>Çok Gizli Kodu Gir!</p>
          <form onSubmit={handleSubmit}>
            <input
              type="tel"
              placeholder="En Gizlisi Olsun"
              value={telefon}
              onChange={(e) => setTelefon(e.target.value)}
              style={{ padding: '10px', fontSize: '1.2em', borderRadius: '5px', border: '2px solid #ff6600' }}
            />
            <button
              type="submit"
              style={{
                padding: '10px 20px',
                fontSize: '1.2em',
                backgroundColor: '#ff6600',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Giriş Yap!
            </button>
          </form>
        </div>
      );
    }

    export default Giris;