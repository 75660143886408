import React, { useState } from 'react';
    import Modal from 'react-modal';

    function Profil({ kullanici, onGeri }) { // onGeri prop'u eklendi
      const [modalAcik, setModalAcik] = useState(false);
      const [secilenFotograf, setSecilenFotograf] = useState('');

      const fotografAc = (fotograf) => {
        setSecilenFotograf(fotograf);
        setModalAcik(true);
      };

      return (
        <div style={{ textAlign: 'center' }}>
          <button
            onClick={onGeri} // Giriş ekranına dönme butonu
            style={{ position: 'absolute', top: '20px', right: '20px', padding: '10px 20px', backgroundColor: '#ff6600', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
          >
            Geri Dön
          </button>
          <h1 style={{ fontFamily: 'Comic Sans MS', fontSize: '3em', color: '#ff6600' }}>{kullanici.ad}</h1>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {kullanici.fotograflar.map((fotograf, index) => (
              <img
                key={index}
                src={fotograf}
                alt={`Fotoğraf ${index + 1}`}
                style={{ width: '150px', height: '150px', margin: '10px', cursor: 'pointer', borderRadius: '10px' }}
                onClick={() => fotografAc(fotograf)}
              />
            ))}
          </div>
          <Modal isOpen={modalAcik} onRequestClose={() => setModalAcik(false)}>
            <img src={secilenFotograf} alt="Büyük Fotoğraf" style={{ maxWidth: '90%', maxHeight: '90%', display: 'block', margin: 'auto' }} />
            <button
              onClick={() => setModalAcik(false)}
              style={{ display: 'block', margin: '20px auto', padding: '10px 20px', backgroundColor: '#ff6600', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
            >
              Kapat
            </button>
          </Modal>
          <div style={{ marginTop: '20px' }}>
          <img
            src={kullanici.komikgif}
            alt="Komik GIF"
            style={{ maxWidth: '300px', marginBottom: '20px' }}
          />
          </div>
          {kullanici.komikIcerik.endsWith('.mp3') ? (
            <video src={kullanici.komikIcerik} controls style={{ maxWidth: '80%', marginTop: '20px' }} />
          ) : (
            <img src={kullanici.komikIcerik} alt="Komik İçerik" style={{ maxWidth: '80%', marginTop: '20px', borderRadius: '10px' }} />
          )}
        </div>
      );
    }

    export default Profil;