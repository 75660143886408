import React, { useState } from 'react';
    import Giris from './Giris';
    import Profil from './Profil';

    function App() {
      const [kullanici, setKullanici] = useState(null);

      const handleGiris = (kullanici) => {
        setKullanici(kullanici);
      };

      const handleGeri = () => {
        setKullanici(null);
      };

      return (
        <div>
          {kullanici ? (
            <Profil kullanici={kullanici} onGeri={handleGeri} />
          ) : (
            <Giris onGiris={handleGiris} />
          )}
        </div>
      );
    }

    export default App;